import React, { Component } from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import Footer from '../../components/footer/';
import Header from '../../components/header/';
import SEO from '../../components/seo';
import Offcanvas from '../../components/offcanvas/';
import style from 'templates/BlogPostGet/BlogPostGet.module.scss';
import Background from '../../components/background';
import Link from '../../components/link';
import Icon from 'components/icon';

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		}
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
	}

	componentWillUnmount() {
		this.setState({ isOpen: false });
	}

	open() {
		this.setState({ isOpen: true });
	}

	close() {
		this.setState({ isOpen: false });
	}

	render() {
		return (
			<>
				<SEO>
					<link rel="stylesheet" href="https://use.typekit.net/uac4quh.css" />
				</SEO>
				<Offcanvas isOpen={this.state.isOpen} offcanvasOpen={this.open} offcanvasClose={this.close} >
					<Header spacer={false} offcanvasOpen={this.open} offcanvasClose={this.close} offcanvasState={this.state.isOpen} />
					<Background className={style.background} image={this.props.image ? this.props.image : "Danos-Default-Placeholder-v2.jpg"}>
						<div className="grid-container">
							<div className="grid-x grid-margin-x">
								<div className={`${style.backToBlogs} cell medium-5 text-right`}><Link to={this.props.link}><Icon className={style.arrow} icon="arrow-left-solid" /> {this.props.goBackText}</Link></div>
							</div>
						</div>
					</Background>
					<main>{this.props.children}</main>
					<Footer enableDanosFoundation={this.props.enableDanosFoundation} enableFooterTabs={this.props.enableFooterTabs} enableBlogFeed={this.props.enableBlogFeed} enableReportsFeed={this.props.enableReportsFeed} />
				</Offcanvas>
			</>
		)
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout