import React from "react"
import { graphql, Link } from 'gatsby'
import Background from 'components/background'
import Layout from "templates/BlogPostGet/layout"
import parse from 'html-react-parser'
import SEO from 'components/seo'
import style from 'templates/BlogPostGet/BlogPostGet.module.scss'
import ReadTime from 'components/readTime'
import Share from 'components/share/'

const BlogPostGet = ({ data, pageContext }) => {
	if (!data.allBlogPost.edges[0] || data.allBlogPost.edges.length > 1 || data.allBlogPost.edges.length === 0) {
		return (
			<Layout>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className={`${style.nothingHere} cell small-12`}>
							<h1>Nothing to see here</h1>
							<p>The post that you are looking for has either moved or no longer exists.</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
	let post = null;
	if (data.allBlogPost && data.allBlogPost.edges.length) post = data.allBlogPost.edges[0].node;
	const monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	const nth = function (d) {
		if (d > 3 & d < 21) return 'th';
		switch (d % 10) {
			case 1: return 'st';
			case 2: return 'nd';
			case 3: return 'rd';
			default: return 'th';
		}
	}
	const d = new Date(Date.parse(post.showDate));
	const dateString = `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
	const metaData = data.site.siteMetadata;
	const twitterHandle = metaData.twitterHandle;
	const title = post.title;
	var schema = {
		"@context": "http://schema.org",
		"@type": "BlogPosting",
		"headline": title,
		"datePublished": new Date(post.showDate).toDateString(),
		"mainEntityOfPage": "True",
		"publisher": {
			"@type": "Organization",
			"name": metaData.title,
		},
		"articleBody": post.body,
	}
	if (post.modified) {
		schema.datemodified = new Date(post.modified).toDateString();
	}
	var SEOimage;
	if (post.photo) {
		schema.image = metaData.siteUrl + post.photo;
		SEOimage = metaData.siteUrl + post.photo;
	}
	var Author = post.Author;

	const typeImage = {
		danos: "/userfiles/Blog Images/2021_pipeline_header_notagline.jpg"
	}

	return (
		<>
			<Layout link="/danos-pipeline/" image={post.headerImage ? post.headerImage : typeImage[post.type]} goBackText="Back to the Pipeline Newsletter" enableDanosFoundation={false} enableFooterTabs={true} enableBlogFeed={true}>
				<section className={style.intro}>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12 medium-8 medium-offset-2">
								<h1>{title}</h1>
								<div className={style.byline}>
									<ReadTime className={style.readTime} data={post.body} prefix='' suffix='Min Read' />
									<div className={style.separator}>|</div>
									<div className={style.date}>{dateString}</div>
								</div>
								{post.photo && !post.headerImage && <img src={post.photo} alt={title} style={{ marginBottom: '1rem' }} />}
							</div>
						</div>
					</div>
					<div className="grid-container">
						<div className="grid-x">
							{!!Author.length > 0 && Author[0]?.object && <div className={`${style.author} cell small-12 medium-2`}>
								<div className={Author[0].object.photo ? style.authorPhoto : 'hide'}>
									<div className={style.image}>
										<Background className={style.photo} image={Author[0].object.photo} />
									</div>
								</div>
								{Author[0].object.link ?
									<p>By: <Link to={Author[0].object.link}>{Author[0].object.firstName} {Author[0].object.lastName}</Link><span className="hide-for-medium"> | </span><br className="show-for-medium" />{Author[0].object.handle}</p> :
									<p>By: {Author[0].object.firstName} {Author[0].object.lastName}<span className="hide-for-medium"> | </span><br className="show-for-medium" />{Author[0].object.handle}</p>}
							</div>}
							<div className="cell small-12 medium-2">
								<Share socialConfig={{
									twitterHandle,
									config: {
										url: `${metaData.siteUrl}${post.uri}`,
										title,
									},
								}} /> 
							</div>
							<div className="cell small-12 medium-8">
								<div className={style.innerContent}>
									{parse(post.body ? post.body : '')}
								</div>
							</div>
						</div>
					</div>
				</section>
			</Layout>
			<SEO title={post ? post.title : ''} image={SEOimage} schema={schema} />
		</>
	)
}

export default BlogPostGet

export const query = graphql`
	query ($uri: String, $lang: String) {
	  allBlogPost(filter: {uri: {eq: $uri}, lang: {eq: $lang}}) {
		edges {
			node {
			  id
			  uri
			  body
			  title
			  photo
			  headerImage
			  permalink
			  intro
			  showDate
			  modified
			  type
			  Author {
				object {
				  permalink
				  photo
				  link
				  firstName
				  emailAddress
				  handle
				  lastName
				}
			  }
			  Topic {
				object {
				  name
				  permalink
				  photo
				  description
				}
			  }
			}
		  }
	  }
	  site {
		siteMetadata {
		  siteUrl
		  twitterHandle
		  title
		  author
		}
	  }
	}
`