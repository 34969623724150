import React, {Component} from 'react'
import readTimeEstimate from 'read-time-estimate'

class ReadTime extends Component {
	render() {
		const className = this.props.className||'';
		const time = readTimeEstimate(this.props.data, 275, 12, 500, ['img', 'Image']);
		var duration = Math.ceil(time.duration);
		var readString = duration;
		if(this.props.prefix) {
			readString = this.props.prefix + ' ' + readString;
		}
		if(this.props.suffix) {
			readString = readString + ' ' + this.props.suffix;
		}
		return (
			<div className={className}>{readString}</div>
		)
	}
}

export default ReadTime;